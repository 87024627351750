$(function(){
    menu_trigger = $('.menu-trigger');
    nav = $('.navbar');
    body = $('body');
    
    

    menu_trigger.click(function () {
        $(this).toggleClass('menu-trigger--active');
        nav.toggleClass('navbar--active');
        // $('body').toggleClass('body--overlay');
        if ($(this).attr('aria-expanded') === 'true' ) {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    $(document).click(function (e) {
        if (!$(menu_trigger).is(e.target)) {
            menu_trigger.removeClass('menu-trigger--active');
            nav.removeClass('navbar--active');
        }
    });

    // $(document).scroll(function(){
    //     if ($(document).scrollTop() >= 40) {
    //         $('nav').addClass('nav--sticky');
    //     }
    //     else {
    //         $('nav').removeClass('nav--sticky');
    //     }
    // });
})