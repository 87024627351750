$(function(){

    // variables
    archive_overlay = $('.archive-overlay');

    project_title = $('.archive-overlay__title');
    project_category = $('.archive-overlay__category');
    project_sector = $('.project-category__sector');
    project_value = $('.project-category__value');
    project_client = $('.project-category__client');
    project_duration = $('.project-category__duration');
    project_description = $('.archive-overlay__content');
    project_img = $('.archive-overlay__img-container .archive-overlay__img');

    gallery_next = $('.archive-overlay__next');
    gallery_prev = $('.archive-overlay__prev');

    /* =================== */


    $('.listing__panel--archive').click(function(){
        // vairables
        project_title_text = $(this).data('project-title');
        project_sector_text = $(this).data('project-sector');
        project_value_text = $(this).data('project-value');
        project_client_text = $(this).data('project-client');
        project_duration_text = $(this).data('project-duration');
        project_description_text = $(this).data('project-description');
        project_img_src = $(this).data('project-img');

        // clear fields
        project_title.html('');
        project_category.html('');
        project_sector.html('');
        project_value.html('');
        project_client.html('');
        project_duration.html('');
        project_description.html('');

        // populate fields
        project_title.html(project_title_text);
        project_category.html(project_sector_text);
        project_sector.html(project_sector_text);
        project_value.html(project_value_text);
        project_client.html(project_client_text);
        project_duration.html(project_duration_text);
        project_description.html(project_description_text);

        // Add active class to first image
        project_img.first().addClass('archive-overlay__img--active');

        // Populate image src for first image
        project_img.first().attr('src', project_img.first().data('src'));

        // stop body from scrolling
        $('body').addClass('no-scroll');

        // show modal
        archive_overlay.addClass('archive-overlay--active');
    });


    /* =================== */

    // Add next / prev if more than one slide
    if (project_img.length > 1) {
        gallery_next.show();
        gallery_prev.show();
    }

    // next image
    gallery_next.click(function() {
        next = $('.archive-overlay__img--active').next();

        if(next.length) {
            next.attr('src', next.data('src'));
            project_img.removeClass('archive-overlay__img--active');
            next.addClass('archive-overlay__img--active');
        } else {
            project_img.removeClass('archive-overlay__img--active');
            project_img.first().addClass('archive-overlay__img--active');
        }
    });

    // prev image
    gallery_prev.click(function() {
        prev = $('.archive-overlay__img--active').prev();

        if(prev.length) {
            prev.attr('src', prev.data('src'));
            project_img.removeClass('archive-overlay__img--active');
            prev.addClass('archive-overlay__img--active');
        } else {
            project_img.last().attr('src', project_img.last().data('src'));
            project_img.removeClass('archive-overlay__img--active');
            project_img.last().addClass('archive-overlay__img--active');
        }
    });


    /* =================== */
    

    // close modal by clicking outside the panel
    archive_overlay.click(function(e){
        clicked = $(e.target);
        if (clicked.is('.archive-overlay__panel') || clicked.parents().is('.archive-overlay__panel')) {
            return;
        } else {
            $('body').removeClass('no-scroll');
            archive_overlay.removeClass('archive-overlay--active');
        }
    });




});
