$(function(){
    $('.accordion__title').click(function () {
        var accordion_content = $(this).parent().find('.accordion__content');
        var accordion_parent = $(this).parent();
        
        $(this).toggleClass('accordion__title--open');
        accordion_content.toggleClass('accordion__content--open');

        if (accordion_parent.attr('aria-expanded') === 'true' ) {
            accordion_parent.attr('aria-expanded', 'false');
        } else {
            accordion_parent.attr('aria-expanded', 'true');
        }

    });
});
