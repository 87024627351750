$(function(){
    var header = $('header');
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    
    if (!is_ie10) {
        if ($(window).width() > 836) {
            $(window).on('scroll load resize', function() {
            // $(window).scroll(function() {
                if ($(this).scrollTop() >= 10) { 
                    header.addClass('header--sticky');
                }
                else {
                    header.removeClass('header--sticky');
                }
            });
        }
    }



})

