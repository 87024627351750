$(function () {
    $('#portfolioFilters .portfolio-filter').click(function (event) {
        var activeClass = 'portfolio-filter--active';

        event.preventDefault();
        var me = $(this);


        //// Add active to filter list 
        $('#portfolioFilters span.' + activeClass).removeClass(activeClass);
        me.addClass(activeClass);

        var filterSector = me.attr('data-sector');
        portfolioFilter(filterSector);

        //// Hide show the panels
        //$('#portfolioList .portfolio-panel').removeClass(panelActive);
        //if (filterSector === "all") {
        //    $('#portfolioList .portfolio-panel').addClass(panelActive);
        //} else {
        //    $('#portfolioList .portfolio-panel.' + filterSector).addClass(panelActive);
        //}
    });
});

function doMobileFilters(me) {
    console.log(me);
    console.log($(me).find('option:selected'));
    
    var filterSector = $(me).find('option:selected').attr('data-sector');
    console.log(filterSector);
    portfolioFilter(filterSector);
}


function portfolioFilter(filterSector) {
    var panelActive = 'portfolio-panel--active';

    console.log('portfolioFilter');

    // Hide show the panels
    $('#portfolioList .portfolio-panel').removeClass(panelActive);
    if (filterSector === "all") {
        $('#portfolioList .portfolio-panel').addClass(panelActive);
    } else {
        $('#portfolioList .portfolio-panel.' + filterSector).addClass(panelActive);
    }
}
